import React from "react"

const defaultContext = {
  language: "english",
  mobileMenuOpen: false,
  setLanguage: () => {},
  toggleLanguage: () => {},
  getLanguage: () => {},
  toggleMobileMenu: () => {},
  closeMobileMenu: () => {},
}

export const AppStateContext = React.createContext(defaultContext)

const isBrowser = typeof window !== "undefined"

const localStorageKeys = {
  lang: "ca.eyehealthmatters.lang",
  pages: "ca.eyehealthmatters.pages",
}

const getLocalStorageItem = key => {
  return isBrowser ? window.localStorage.getItem(key) : null
}

const setLocalStorageItem = (key, value) => {
  isBrowser && window.localStorage.setItem(key, value)
}

const getDefaultLanguage = () => {
  if (isBrowser) {
    const browserLanguage = navigator.language || navigator.userLanguage
    if (browserLanguage.startsWith("fr-")) {
      return "french"
    }
  }

  return "english"
}

export class AppStateProvider extends React.Component {
  constructor(props) {
    super(props)

    let language = getLocalStorageItem(localStorageKeys.lang)
    if (!language || !["english", "french"].includes(language)) {
      language = getDefaultLanguage()
    }

    this.state = {
      language: language,
      mobileMenuOpen: false,
    }
  }

  setLanguage = language => {
    if (!language || !["english", "french"].includes(language)) {
      language = "english"
    }

    this.setState({
      language: language,
    })

    setLocalStorageItem(localStorageKeys.lang, language)
    this.loadLanguage(language)
  }

  loadLanguage = (language, retryCount = 0) => {
    if (isBrowser && window.OneTrust) {
      const langCode = language === "french" ? "fr-ca" : "en"
      window.OneTrust.changeLanguage(langCode)
    } else if (retryCount < 10) {
      const loadLanguage = this.loadLanguage
      setTimeout(function () {
        loadLanguage(language, retryCount + 1)
      }, (2 + retryCount) * 50)
    }
  }

  toggleLanguage = () => {
    const language = this.state.language === "french" ? "english" : "french"
    this.setLanguage(language)
  }

  toggleMobileMenu = () => {
    const mobileMenuOpen = this.state.mobileMenuOpen === false ? true : false
    this.setState({
      mobileMenuOpen: mobileMenuOpen,
    })

    const html = document.getElementsByTagName("html")[0]
    if (mobileMenuOpen) html.style.overflow = "hidden"
    else html.style.overflow = "auto"
  }

  closeMobileMenu = () => {
    setTimeout(() => {
      this.setState({
        mobileMenuOpen: false,
      })
    }, 50)
    const html = document.getElementsByTagName("html")[0]
    html.style.overflow = "auto"
  }

  setLoggedIn = loggedIn => {
    this.setState({
      isLoggedIn: loggedIn,
    })
  }

  getValue = () => {
    return {
      ...this.state,
      setLanguage: this.setLanguage,
      toggleLanguage: this.toggleLanguage,
      toggleMobileMenu: this.toggleMobileMenu,
      closeMobileMenu: this.closeMobileMenu,
    }
  }

  render() {
    return (
      <AppStateContext.Provider value={this.getValue()}>
        {this.props.children}
      </AppStateContext.Provider>
    )
  }
}

export function wrapRootElement({ element }) {
  return <AppStateProvider>{element}</AppStateProvider>
}
